<template>
  <!-- Page Content  -->
  <b-container fluid>
    <b-row>
      <!-- BEGIN:Table -->
      <b-col md="12">
        <iq-card
          bodyClass="relative-background"
          class="iq-card-block iq-card-stretch iq-card-height"
        >
          <template v-slot:headerTitle>
            <h4 class="card-title">Jenis Dokumen</h4>
          </template>
          <template v-slot:body>
            <!-- Header -->
            <div class="py-2">
              <b-row>
                <b-col>
                  <b-btn class="btn-block" variant="success" @click="showPks"
                    >PKS</b-btn
                  >
                </b-col>
                <b-col>
                  <b-btn class="btn-block" variant="secondary" @click="showMou"
                    >MoU</b-btn
                  >
                </b-col>
                <b-col>
                  <b-btn class="btn-block" variant="info" @click="showKontrak"
                    >Kontrak</b-btn
                  >
                </b-col>
                <b-col>
                  <b-btn class="btn-block" variant="primary" @click="showIa"
                    >IA</b-btn
                  >
                </b-col>
                <b-col>
                  <b-btn class="btn-block" variant="warning" @click="showSk"
                    >Surat Kuasa</b-btn
                  >
                </b-col>
              </b-row>
            </div>
            <PKS v-show="isPks === true" />
            <MoU v-show="isMou === true" />
            <Kontrak v-show="isKontrak === true" />
            <IA v-show="isIa === true" />
            <SK v-show="isSk === true" />
          </template>
        </iq-card>
      </b-col>
      <!-- ./END:Table -->
    </b-row>
  </b-container>
</template>
<script>
// import axios from 'axios'
import store from '@/store'
import { mapState } from 'vuex'
import { ID_GROUP } from '@/types'
import PKS from '@/views/Pages/Kerjasama/JenisDokumen/Pks/Main'
import MoU from '@/views/Pages/Kerjasama/JenisDokumen/Mou/Main'
import Kontrak from '@/views/Pages/Kerjasama/JenisDokumen/Kontrak/Main'
import IA from '@/views/Pages/Kerjasama/JenisDokumen/Ia/Main'
import SK from '@/views/Pages/Kerjasama/JenisDokumen/Sk/Main'

export default {
  name: 'kerjasama.jenisDokumen',
  components: {
    PKS,
    MoU,
    Kontrak,
    IA,
    SK
  },
  computed: {
    ...mapState('authService', ['token'])
  },
  mounted() {
    /** Innit data */
    this.innitData()
  },
  methods: {
    resetShow() {
      this.listData = []
      this.isPks = false
      this.isMou = false
      this.isKontrak = false
      this.isIa = false
      this.isSk = false
    },
    /**
     * Get data Kerjasama PKS
     */
    // async getDataPks_ () {
    //   const result = await axios({
    //     url: `${process.env.VUE_APP_URL_PUBLIC}/getListTransaksiPks`,
    //     method: 'GET',
    //     headers: {
    //       'Content-Type': 'multipart/form-data',
    //       Authorization: 'Bearer ' + this.token
    //     }
    //   })
    //     .then(res => {
    //       this.listData = res.data
    //     })
    //     .catch(err => {
    //       console.log('ERROR', err)
    //       throw new Error(err)
    //     })
    //   return result
    // },
    async showPks() {
      this.resetShow()
      // this.getDataPks_()
      this.isPks = true
    },
    async showMou() {
      this.resetShow()
      this.isMou = true
    },
    async showKontrak() {
      this.resetShow()
      this.isKontrak = true
    },
    async showIa() {
      this.resetShow()
      this.isIa = true
    },
    async showSk() {
      this.resetShow()
      this.isSk = true
    },
    /**
     * Init Data
     */
    async innitData() {
      /** Get List Data */
    }
  },
  data() {
    return {
      listIdGroup: ID_GROUP,
      idGroup: store.state.authService.idGroup,
      isPks: false,
      isMou: false,
      isKontrak: false,
      isIa: false,
      isSk: false
      // listData: []
    }
  },
  beforeDestroy() {
    this.resetShow()
  }
}
</script>
